import type { GatsbyBrowser } from 'gatsby';
import { navigate } from 'gatsby';
import './src/styles/global.css';
import '@fontsource/inter';
import '@fontsource/montserrat';
import '@fontsource/ibm-plex-mono';
import '@fontsource/open-sans';
import '@fontsource/dm-sans';
import '@fontsource/rubik';
import '@fontsource/quicksand';
import '@fontsource/museomoderno';

import {
  MAINTENANCE_PAGE,
  PAGES_UNDER_MAINTENANCE,
} from './src/constants/utility';

export const onPreRouteUpdate: GatsbyBrowser['onPreRouteUpdate'] = ({
  location,
  prevLocation,
}) => {
  const isPageUnderMaintenance = (page) => {
    const foundPage = PAGES_UNDER_MAINTENANCE.find((pages) => pages === page);
    if (foundPage) {
      return true;
    } else {
      return false;
    }
  };

  if (
    process.env.IN_SITE_MAINTENANCE === 'true' &&
    isPageUnderMaintenance(prevLocation?.pathname)
  ) {
    navigate(MAINTENANCE_PAGE);
  }
};
